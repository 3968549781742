/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES (INITIALIZATION) ----------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- IMPORTS ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

import.meta.glob([
	'../images/**',
	'../fonts/**',
	'../videos/**',
]);

import './bootstrap';
import * as img_func from './image-functions';
import { tone_card, countries } from './variables';

import $ from "jquery";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';
import { Draggable } from 'gsap/Draggable';
import { Observer } from 'gsap/Observer';
import Splide from '@splidejs/splide';
import { _ } from 'lodash';
import * as faceapi from "face-api.js";
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'

Alpine.plugin( focus );
gsap.registerPlugin( ScrollSmoother, ScrollTrigger, ScrambleTextPlugin, ScrollToPlugin, SplitText, Draggable, Observer );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

let recordedResult = undefined;

window.Alpine = Alpine;
window.$ = $;

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- GENERAL ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

const hasTouchSupport = () => {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const isMobileSize = () => {
	const minWidth = 768; // Minimum width for desktop devices
	console.log( window.innerWidth, screen.width, minWidth );
  	return window.innerWidth < minWidth || screen.width < minWidth;
};

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

    var colors = [ [ '#FAD6C0', '#E8C3A8', '#DCB58C', '#DCA475' ], [ '#DF9D7B', '#C6926A', '#9F664B', '#834432' ], [ '#6D3C2E', '#723A29', '#5C2E1F', '#773C2A' ] ];

    for (var i = 0; i < scope.length; i++)
    {
	    scope.eq( i ).append( "<div class='logo-palette'></div>" );

	    var grid = new Array();
	    for (var x = 0; x < 3; x++)
	    {
	    	grid.push( new Array() );
		    for (var y = 0; y < 4; y++)
		    {
		    	grid[ x ].push( new Array() );
		    	grid[ x ][ y ] = scope.eq( i ).find( '.logo-palette' ).append( "<div class='palette'></div>" ).children( '.palette' ).eq( ( x * 4 ) + y );
		    	grid[ x ][ y ].css( "background-color", colors[ x ][ y ] );
		    	gsap.to( grid[ x ][ y ], { opacity: "random( 0.1, 0.5, 0.05 )", duration: "random( 1, 2, 0.25 )", repeat: -1, yoyo: true } );
		    }
	    }
    }
    
})( '.logo-palette-holder', '.logo-palette-holder.sm' );



( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	$( document ).ready( function()
	{
	    scope.removeClass( 'loading' );
	});

})( 'body[data-section="home"], body[data-section="webapp"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- WEBAPP ------------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	document.addEventListener( 'alpine:init', () => {
	    Alpine.data( 'videoError', () => ({
	        error: false,
	        txt: '',
			init() {
				this.$watch( 'error', () => {
					$( '.video-container >.face-api-info' )[ this.error ? 'addClass': 'removeClass' ]( 'error' );
					$( '.video-container >.face-api-info .text' ).text( this.txt );
				})
			}
	    })),
	    Alpine.data( 'modalClose', () => ({
	        open: false,
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
					gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
				})
			}
	    })),
	    Alpine.data( 'modalReturn', () => ({
	        open: false,
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
					gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
				})
			}
	    })),
	    Alpine.data( 'modalSubmitted', () => ({
	        open: false,
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
				})
			}
	    })),
	    Alpine.data( 'modalContentLicense', () => ({
	        open: false,
	        toggle() { this.open = !this.open; },
			// init() {
			// 	this.$watch('open', () => {
			// 		if( this.open )
			// 		{
			// 			setTimeout( function() {
			// 				$( '#modalContentLicense .modal-holder' ).addClass( 'scrollable' );
			// 			}, 300 );
						
			// 		}
			// 		else
			// 		{
			// 			$( '#modalContentLicense .modal-holder' ).removeClass( 'scrollable' );
			// 		}
			// 	})
			// }
	    })),
	    Alpine.data( 'modalDataUse', () => ({
	        open: false,
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
				})
			}
	    })),
	    Alpine.data( 'frmCountryDropdown', () => ({
	    	allOptions: countries,
	        options: [],
	        isOpen: false,
	        openedWithKeyboard: false,
	        selectedOption: null,
			init() {
				this.options = this.allOptions;
			},
	        setSelectedOption(option) {
	            this.selectedOption = option
	            this.isOpen = false
	            this.openedWithKeyboard = false
	            this.$refs.hiddenTextField.value = option.alpha2

	            $( this.$el ).parents( '.frm-select' ).removeClass( 'error' );
	        },
	        getFilteredOptions(query) {
	            this.options = this.allOptions.filter((option) =>
	                option.country.toLowerCase().includes(query.toLowerCase()),
	            )
	            if (this.options.length === 0) {
	                this.$refs.noResultsMessage.classList.remove('hidden')
	            } else {
	                this.$refs.noResultsMessage.classList.add('hidden')
	            }
	        },
	        handleKeydownOnOptions(event) {
	            // if the user presses backspace or the alpha-numeric keys, focus on the search field
	            if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 8) {
	                this.$refs.searchField.focus()
	            }
	        },
	    }));
	});

	window.videoError_true  = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.txt = txt; ref.error = true; }
	window.videoError_false = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.txt = txt; ref.error = false; }

	window.modalClose_open  = function ( e ) { var ref = document.getElementById( 'modalClose' )._x_dataStack[ 0 ]; ref.open = true; }
	window.modalClose_close = function ( e ) { var ref = document.getElementById( 'modalClose' )._x_dataStack[ 0 ]; ref.open = false; }

	window.modalReturn_open  = function ( e ) { var ref = document.getElementById( 'modalReturn' )._x_dataStack[ 0 ]; ref.open = true; }
	window.modalReturn_close = function ( e ) { var ref = document.getElementById( 'modalReturn' )._x_dataStack[ 0 ]; ref.open = false; }

	window.modalSubmitted_open  = function ( e ) { var ref = document.getElementById( 'modalSubmitted' )._x_dataStack[ 0 ]; ref.open = true; }
	window.modalSubmitted_close = function ( e ) { var ref = document.getElementById( 'modalSubmitted' )._x_dataStack[ 0 ]; ref.open = false; }

	window.modalContentLicense_open  = function ( e ) { var ref = document.getElementById( 'modalContentLicense' )._x_dataStack[ 0 ]; ref.open = true; }
	window.modalContentLicense_close = function ( e ) { var ref = document.getElementById( 'modalContentLicense' )._x_dataStack[ 0 ]; ref.open = false; }

	window.modalDataUse_open  = function ( e ) { var ref = document.getElementById( 'modalDataUse' )._x_dataStack[ 0 ]; ref.open = true; }
	window.modalDataUse_close = function ( e ) { var ref = document.getElementById( 'modalDataUse' )._x_dataStack[ 0 ]; ref.open = false; }

	Alpine.start();

    let cam_facing = 'user'; // 'user' or 'environment'
    let cam_facing_prev = cam_facing;
    let cam_state = 'loading';
    // let cam_state_prev = cam_state;
	let splide;

	const draw_method = 'raf'; // [ "st", "raf" ] // setTimeout or requestAnimationFrame
    const result_display = false;

    async function trackVideoAction( capture = false )
    {
    	const video = $( '#faceAPIVideo' ).get( 0 );
    	const cam_booting = video.paused || video.ended || !img_func.isFaceDetectionModelLoaded();

    	if( cam_state == 'complete' )
    	{
    		// cam_state_prev = cam_state;
	    	if( !$( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).addClass(    'loading' );
	    	if(  $( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).removeClass( 'loading' );
	    	if( !$( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).addClass(    'loading' );
	    	if(  $( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).removeClass( 'loading' );
	    	if(  $( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).removeClass( 'disabled' );
    		return capture ? false: ( draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } ) );
    	}
    	else if( cam_state == 'analyzing' )
    	{
    		// cam_state_prev = cam_state;
			video.pause();
	    	if( !$( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).addClass(    'loading' );
	    	if(  $( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).removeClass( 'loading' );
	    	if(  $( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).removeClass( 'loading' );
	    	if(  $( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).removeClass( 'loading' );
	    	if( !$( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).addClass( 'disabled' );
    		return capture ? false: ( draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } ) );
    	}
    	else if( cam_state == 'captured' )
    	{
    		// cam_state_prev = cam_state;
			video.pause();
			videoError_false( 'Analyzed' );
	    	if( !$( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).addClass(    'loading' );
	    	if(  $( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).removeClass( 'loading' );
	    	if(  $( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).removeClass( 'loading' );
	    	if(  $( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).removeClass( 'loading' );
	    	if(  $( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).removeClass( 'disabled' );
    		return capture ? false: ( draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } ) );
    	}
    	
    	if( cam_facing_prev != cam_facing )
    	{
    		cam_state = 'switching';
    		videoError_true( 'Switching' );
	    	if( !$( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).addClass( 'loading' );
	    	if( !$( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).addClass( 'loading' );
	    	if( !$( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).addClass( 'loading' );
	    	if( !$( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).addClass( 'loading' );
	    	if( !$( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).addClass( 'disabled' );
    		return capture ? false: ( draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } ) );
    	}

    	if( cam_booting )
    	{
    		cam_state = 'loading';
    		videoError_true( 'Loading' );
	    	if( !$( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).addClass( 'loading' );
	    	if( !$( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).addClass( 'loading' );
	    	if( !$( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).addClass( 'loading' );
	    	if( !$( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).addClass( 'loading' );
	    	if( !$( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).addClass( 'disabled' );
    		return capture ? false: ( draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } ) );
    		
    		// return false;
    	}

    	cam_state = 'ready';
    	cam_facing_prev = cam_facing;
    	if(  $( '#faceAPIVideo'     ).hasClass( 'loading' ) ) $( '#faceAPIVideo'     ).removeClass( 'loading' );
    	if(  $( '#faceAPIOverlay'   ).hasClass( 'loading' ) ) $( '#faceAPIOverlay'   ).removeClass( 'loading' );
    	if(  $( '.canvas-info'      ).hasClass( 'loading' ) ) $( '.canvas-info'      ).removeClass( 'loading' );
    	if(  $( '.carousel-actions' ).hasClass( 'loading' ) ) $( '.carousel-actions' ).removeClass( 'loading' );
    	if(  $( '.carousel-actions' ).hasClass( 'disabled' ) ) $( '.carousel-actions' ).removeClass( 'disabled' );

    	if( result_display ) $( '#faceAPIOverlay' ).removeClass( 'loading' );

    	const can_dims = { width: $( '.video-container' ).width(), height: $( '.video-container' ).height() };
    	const vid_dims = { width: $( '#faceAPIVideo' ).width(), height: $( '#faceAPIVideo' ).height() };
    	const cam_dims = { width: video.videoWidth, height: video.videoHeight };
		const scale = Math.min( ( vid_dims.width / can_dims.width ), ( vid_dims.height / can_dims.height ) );
	    const scaled_w = can_dims.width * scale;
	    const scaled_h = can_dims.height * scale;
		const scale_up = Math.min( ( cam_dims.width / scaled_w ), ( cam_dims.height / scaled_h ) );
    	const canvas = $( '#faceAPIOverlay' ).get( 0 );
    		  canvas.width = can_dims.width;
    		  canvas.height = can_dims.height;
	    const ctx = canvas.getContext( '2d' );

	    let sx = ( cam_dims.width - ( scaled_w * scale_up ) ) / 2;
	    let sy = ( cam_dims.height - ( scaled_h * scale_up ) ) / 2;
	    let sWidth = scaled_w * scale_up;
	    let sHeight = scaled_h * scale_up;
	    let dx = 0;
	    let dy = 0;
	    let dWidth = can_dims.width;
	    let dHeight = can_dims.height;

    	const facingMode = video.srcObject.getVideoTracks()[ 0 ].getConstraints().facingMode;
		    if( facingMode == 'user' )
    	  	{
    	  		ctx.save();
    	  		ctx.translate( can_dims.width, 0 );
    	  		ctx.scale( -1, 1 );
			}
			ctx.drawImage( video, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight );
			if( facingMode == 'user' ) ctx.restore();
    	const options = img_func.getFaceDetectorOptions();
    	const result = await faceapi.detectAllFaces( canvas, options );

    	if( result )
    	{
    		switch( result.length )
    		{
    			case 0: videoError_true( 'Detecting' ); break;
    			case 1: videoError_false( capture ? 'Captured': 'Ready' );
					if( result_display ) faceapi.draw.drawDetections( canvas, faceapi.resizeResults( result[ 0 ], can_dims ) );
    				if( capture )
    				{
						recordedResult = {
							image: canvas.toDataURL( 'image/jpeg' ),
							result: result[ 0 ],
							tone: undefined,
						};
    				}
    				break;
    			default: videoError_true( 'Multiple Faces Detected' ); break;
    		}
    	}
    	else
		{
			videoError_true( 'Detecting' );
		}

    	if( !capture ) draw_method == 'st' ? setTimeout( () => trackVideoAction() ): requestAnimationFrame( function() { trackVideoAction( capture ); } );
    }


	async function return_action( e )
    {
    	let active_id = $( 'section[ id^="section-" ].active' ).attr( 'id' );

    	hide_submit_form();
    	modalReturn_close();
    	splide.refresh();

    	switch( active_id )
    	{
    		case 'section-1':
				await retake_action();
				$( '.btn-return' ).addClass( 'invisible' );
    			break;
    		case 'section-2':
    			break;
    		default: break;
    	}
    }

	async function close_action( e )
    {
		alert( 'go to homepage' );
    }

	async function snap_action( e )
    {
    	await trackVideoAction( true );
	
		if( recordedResult )
		{
			cam_state = 'analyzing';
			videoError_false( 'Analyzing' );
			$( '.carousel-actions' ).addClass( 'no-pointer-events' );

    		gsap.to( $( '.video-container >.face-api-info .text' ), {
				duration: 1,
				scrambleText: {
					text: "Analyzing",
					chars: "upperCase",
					// revealDelay: 0.1,
					speed: 0.3,
				},
				repeat: 0,
				yoyo: true,
				onComplete: function()
				{
					cam_state = 'captured';

					if( $( '.section-1 .result-container' ).length > 0 )
						$( '.section-1 .result-container' ).remove();
					let $con = result_display ? $( '.section-1' ).append( '<div class="result-container" />' ).find( '.result-container' ): $( '<div class="result-container" />' );

					let canvas_result = $con.append( '<canvas id="resultOverlay" />' ).find( '#resultOverlay' ).get( 0 );
						canvas_result.left   = recordedResult.result.box.left;
						canvas_result.top    = recordedResult.result.box.top;
						canvas_result.width  = recordedResult.result.box.width;
						canvas_result.height = recordedResult.result.box.height;
					let result_ctx = canvas_result.getContext( '2d' );
					let result_img = new Image;
						result_img.onload = function()
						{
							result_ctx.drawImage(
								result_img,
								canvas_result.left,
								canvas_result.top,
								canvas_result.width,
								canvas_result.height,
								0, 0, canvas_result.width, canvas_result.height
							);

							let extract_data = result_ctx.getImageData( 0, 0, canvas_result.width, canvas_result.height );
							let extract_data_filtered = new ImageData( img_func.filterSkin( extract_data.data ), extract_data.width, extract_data.height, { colorSpace: extract_data.colorSpace } );
							let extract_result = $con.append( '<canvas id="extractOverlay" />' ).find( '#extractOverlay' ).get( 0 );
								extract_result.width  = extract_data_filtered.width;
								extract_result.height = extract_data_filtered.height;
							let extract_ctx = extract_result.getContext( '2d' );
								extract_ctx.putImageData( extract_data_filtered, 0, 0 );

							/**
							* getImageData returns an array full of RGBA values
							* each pixel consists of four values: the red value of the colour, the green, the blue and the alpha
							* (transparency). For array value consistency reasons,
							* the alpha is not from 0 to 1 like it is in the RGBA of CSS, but from 0 to 255.
							*/
							const imageData = extract_ctx.getImageData( 0, 0, extract_data_filtered.width, extract_data_filtered.height );

							// Convert the image data to RGB values so its much simpler
							const rgbArray = img_func.buildRgb(imageData.data);

							/**
							* Color quantization
							* A process that reduces the number of colors used in an image
							* while trying to visually maintain the original image as much as possible
							*/
							let quantColors = img_func.quantization(rgbArray, 0);
							let avgColor = undefined;

							for (var i = 0; i < quantColors.length; i++) {
								let val = img_func.RGBToHex( quantColors[ i ] );
								if( val != "#000000" )
								{
									if( avgColor == undefined ) avgColor = val;
									else avgColor = '#' + img_func.averageRGB( avgColor, val );
								}
							}

							if( avgColor != undefined )
							{
								let tone_color = img_func.closestColor( avgColor, tone_card );
								let tone_result = $con.append( '<canvas id="toneOverlay" />' ).find( '#toneOverlay' ).get( 0 );
									tone_result.width  = extract_data_filtered.width;
									tone_result.height = extract_data_filtered.height;
								let tone_ctx = tone_result.getContext( '2d' );
									tone_ctx.fillStyle = tone_color;
									tone_ctx.fillRect( 0, 0, tone_result.width, tone_result.height);

					    		var $ref = $( '.canvas-info' );
					    		gsap.to( $ref.find( '.header' ), {
									duration: 1,
									scrambleText: {
										text: "MY TONE IS " + tone_color.toUpperCase(),
										chars: "upperCase",
										revealDelay: 0.1,
										speed: 0.3,
									}
								});

								recordedResult.tone = tone_color;
								$( '.section-1' ).addClass( 'split' );
								$( '.tone-results .tone-color .bg-color' ).css( 'background-color', tone_color );
								$( '.tone-results .tone-value' ).text( tone_color.toUpperCase() );
								splide.go( 1 );

								console.log( "-------------------------" );
								console.log( "Average color is", avgColor.toUpperCase() );
								console.log( "Tone Card value is", tone_color.toUpperCase() );
								console.log( "-------------------------" );
							}
							else
							{





/* ISSUE HERE, NEED TO CATER FOR INVALID or little SKIN TONE */






						    	cam_state = 'loading';

								const canvas = $( '#faceAPIOverlay' ).get( 0 );
							    const ctx = canvas.getContext( '2d' );
									  ctx.clearRect( 0, 0, canvas.width, canvas.height );

						    	const video = $( '#faceAPIVideo' ).get( 0 );
						    		  video.play();

								if( recordedResult )
								{
									recordedResult = undefined;
									if( $( '.section-1 .result-container' ).length > 0 )
										$( '.section-1 .result-container' ).remove();
								}

								alert( 'An error has occurred!' );

							}
						};

					recordedResult.pos1 = {
						left: ( ( $( '#faceAPIOverlay' ).width()  - $( '.canvas-info' ).outerWidth()  ) / 2 ),
						top:  ( ( $( '#faceAPIOverlay' ).height() - $( '.canvas-info' ).outerHeight() ) / 2 )
					}
					recordedResult.pos2 = {
						left: ( ( $( '.canvas-info' ).outerWidth()  - $( '.canvas-info .title' ).outerWidth()  ) / 2 ),
						top:  ( ( $( '.canvas-info' ).outerHeight() - $( '.canvas-info .title' ).outerHeight() ) / 2 )
					}
					recordedResult.pos3 = {
						left: ( ( $( '.canvas-info' ).outerWidth()  - $( '.canvas-info .title' ).outerWidth()  ) / 2 ),
						top:  ( ( $( '.canvas-info' ).outerHeight() - $( '.canvas-info .title' ).outerHeight() ) / 2 ) + $( '.canvas-info .title .header' ).outerHeight()
					}
					recordedResult.src_dims = {
						width:  $( '#faceAPIOverlay' ).width(),
						height: $( '#faceAPIOverlay' ).height()
					}
					recordedResult.info_dims = {
						width:  $( '.canvas-info' ).outerWidth(),
						height: $( '.canvas-info' ).outerHeight()
					}

					result_img.src = recordedResult.image; // binary data
					$( '.carousel-actions' ).removeClass( 'no-pointer-events' );
					gsap.fromTo( $( '#faceAPIOverlay' ), { webkitFilter: "brightness(10)", filter: "brightness(10)" }, { webkitFilter: "brightness(1)", filter: "brightness(1)", duration: 0.75, ease: "power2.out" } );
				}
			});
		}
		else
		{
			videoError_true( 'Detecting' );
		}
    }

	async function cam_facing_action( e )
    {
    	// $( '#faceAPIVideo' ).addClass( 'loading' );
    	// $( '.canvas-info'  ).addClass( 'loading' );
    	// $( '.carousel-actions' ).addClass( 'loading' );

    	switch( cam_facing )
    	{
    		case 'user': cam_facing = 'environment'; break;
    		case 'environment': cam_facing = 'user'; break;
    		default: cam_facing = 'user'; break;
    	}

    	setTimeout( function() {
			let camera = runCamera( cam_facing );
    	}, 100 );
    }

	async function retake_action( e )
    {
    	cam_state = 'loading';
    	$( '.section-1' ).removeClass( 'split' );
		splide.go( 0 );

		const canvas = $( '#faceAPIOverlay' ).get( 0 );
	    const ctx = canvas.getContext( '2d' );
			  ctx.clearRect( 0, 0, canvas.width, canvas.height );

    	const video = $( '#faceAPIVideo' ).get( 0 );
    		  video.play();

		if( recordedResult )
		{
			recordedResult = undefined;
			if( $( '.section-1 .result-container' ).length > 0 )
				$( '.section-1 .result-container' ).remove();
		}

		var $ref = $( '.canvas-info' );
		gsap.to( $ref.find( '.header' ), {
			duration: 1,
			scrambleText: {
				text: "MY TONE IS",
				chars: "upperCase",
				revealDelay: 0.1,
				speed: 0.3,
			}
		});
    }

	async function next_action( e )
    {
    	cam_state = "complete";
		$( '.btn-return' ).removeClass( 'invisible' );
    	$( '.section-1' ).addClass( 'split' );
		splide.go( 2 );

		if( recordedResult )
		{
			let result_img = new Image;
				result_img.recordedResult = recordedResult;
				result_img.onload = function()
				{
		    		let canvas = $( '#faceAPIOverlay' ).get( 0 );
					const size = { width: canvas.width, height: canvas.height };
					const ratio = 2;

	    			canvas.width = size.width * ratio;
	    			canvas.height = size.height * ratio;

				    let ctx = canvas.getContext( '2d' );
				    	// ctx.save();
		    			// ctx.scale( ratio, ratio );
				    	ctx.imageSmoothingQuality = "high";
				    	ctx.drawImage( result_img, 0, 0, canvas.width, canvas.height );
				    	// ctx.restore();

					let $ref_info_parent = $( '.canvas-info' );
					let $ref_info = $( '.canvas-info .title' );

		    		let $tgt = $( '#faceAPIOverlay' );
		    		let tgt_canvas = $tgt.get( 0 );
		    		let tgt_ctx = tgt_canvas.getContext( '2d' );

		    			tgt_ctx.save();
		    			// tgt_ctx.scale( ratio, ratio );
		    			tgt_ctx.textRendering = "geometricPrecision";
		    			tgt_ctx.imageSmoothingQuality = "high";
						tgt_ctx.font = ( 20 * ratio ) + "px HurmeGeometricSans3-SemiBold";
						tgt_ctx.textBaseline = "top";
						tgt_ctx.letterSpacing = "0px";
						tgt_ctx.fillStyle = "#FFFFFF";
						tgt_ctx.shadowColor = "rgb( 0 0 0 / 60% )";
						tgt_ctx.shadowBlur = 2;
						tgt_ctx.shadowOffsetX = 1;
						tgt_ctx.shadowOffsetY = 1;
						tgt_ctx.fillText( "MY TONE IS " + this.recordedResult.tone.toUpperCase(), ( recordedResult.pos1.left + recordedResult.pos2.left ) * ratio, ( recordedResult.pos1.top + recordedResult.pos2.top ) * ratio );

						tgt_ctx.font = ( 10 * ratio ) + "px HurmeGeometricSans3-Regular";
						tgt_ctx.textBaseline = "top";
						tgt_ctx.letterSpacing = "0px";
						tgt_ctx.fillStyle = "#FFFFFF";
						tgt_ctx.shadowColor = "rgb( 0 0 0 / 60% )";
						tgt_ctx.shadowBlur = 2;
						tgt_ctx.shadowOffsetX = 1;
						tgt_ctx.shadowOffsetY = 1;
						tgt_ctx.fillText( "Capture yours at 268ToneProud.com", ( recordedResult.pos1.left + recordedResult.pos3.left ) * ratio, ( recordedResult.pos1.top + recordedResult.pos3.top ) * ratio );
						tgt_ctx.restore();

					let scaled = {
						x: ( ( recordedResult.src_dims.width  - recordedResult.info_dims.width  ) / 2 ) * ratio,
						y: ( ( recordedResult.src_dims.height - recordedResult.info_dims.height ) / 2 ) * ratio,
						w: ( recordedResult.src_dims.width  ) * ratio,
						h: ( recordedResult.src_dims.height ) * ratio,
					};
					let tgt_len = 25 * ratio;

					(async () => {
						let frame_tl = new Image();
							frame_tl.onload = function() {
								tgt_ctx.drawImage( frame_tl, 0, 0, frame_tl.width, frame_tl.height, scaled.x, scaled.y, tgt_len, tgt_len );
							}
							frame_tl.crossOrigin = "anonymous";
							frame_tl.src = $ref_info_parent.data( 'frame-tl' );
						await frame_tl.decode();

						let frame_tr = new Image();
							frame_tr.onload = function() {
								tgt_ctx.drawImage( frame_tr, 0, 0, frame_tr.width, frame_tr.height, scaled.w - scaled.x - tgt_len, scaled.y, tgt_len, tgt_len );
							}
							frame_tr.crossOrigin = "anonymous";
							frame_tr.src = $ref_info_parent.data( 'frame-tr' );
						await frame_tr.decode();

						let frame_bl = new Image();
							frame_bl.onload = function() {
								tgt_ctx.drawImage( frame_bl, 0, 0, frame_bl.width, frame_bl.height, scaled.x, scaled.h - scaled.y - tgt_len, tgt_len, tgt_len );
							}
							frame_bl.crossOrigin = "anonymous";
							frame_bl.src = $ref_info_parent.data( 'frame-bl' );
						await frame_bl.decode();

						let frame_br = new Image();
							frame_br.onload = function() {
								tgt_ctx.drawImage( frame_br, 0, 0, frame_br.width, frame_br.height, scaled.w - scaled.x - tgt_len, scaled.h - scaled.y - tgt_len, tgt_len, tgt_len );
							}
							frame_br.crossOrigin = "anonymous";
							frame_br.src = $ref_info_parent.data( 'frame-br' );
						await frame_br.decode();

						let frame_watermark = new Image();
							frame_watermark.onload = function() {
								console.log( frame_watermark.width, frame_watermark.height );
								tgt_ctx.drawImage( frame_watermark, 0, 0, frame_watermark.width, frame_watermark.height,
												   ( tgt_canvas.width / 2 ) - ( frame_watermark.width * ratio / 2 ),
												   ( tgt_canvas.height ) - ( frame_watermark.height * ratio ) * 2,
												   frame_watermark.width * ratio,
												   frame_watermark.height * ratio );
							}
							frame_watermark.crossOrigin = "anonymous";
							frame_watermark.src = $ref_info_parent.data( 'frame-watermark' );
						await frame_watermark.decode();

					})().then( () => {
						setTimeout( () => {
							result_img.onload = undefined;
							result_img.src = tgt_canvas.toDataURL( 'image/jpeg' );

							let form_canvas = $( '#section-2 #uploadImage' ).get( 0 );
								form_canvas.width = tgt_canvas.width;
								form_canvas.height = tgt_canvas.height;
							let form_ctx = form_canvas.getContext( '2d' );
							form_ctx.drawImage( tgt_canvas, 0, 0, tgt_canvas.width, tgt_canvas.height, 0, 0, form_canvas.width, form_canvas.height );

							tgt_canvas.toBlob( ( blob ) => {
								$( '.carousel-actions .step3 .btn-download' ).attr( 'href', URL.createObjectURL( blob ) );
							}, "image/jpeg" );
						}, 10 );
					});

	    			// canvas.width = size.width;
	    			// canvas.height = size.height;
				};

			result_img.src = recordedResult.image; // binary data

			// recordedResult = undefined;
			if( $( '.section-1 .result-container' ).length > 0 )
				$( '.section-1 .result-container' ).remove();
		}

    	// if( !$( '.canvas-info'    ).hasClass( 'loading' ) ) $( '.canvas-info'    ).addClass(    'loading' );
    	// if(  $( '#faceAPIOverlay' ).hasClass( 'loading' ) ) $( '#faceAPIOverlay' ).removeClass( 'loading' );
		gsap.fromTo( $( '#faceAPIOverlay' ), { webkitFilter: "brightness(10)", filter: "brightness(10)" }, { webkitFilter: "brightness(1)", filter: "brightness(1)", duration: 0.75, ease: "power2.out" } );
		gsap.to(     $( '#faceAPIOverlay' ), { scale: 1.2, duration: 0.2, ease: "power2.out", repeat: 1, yoyo: true } );
		// gsap.fromTo( $( '#faceAPIOverlay' ), { scale: 2 }, { scale: 1, duration: 0.75, ease: "power2.out" } );
    }

	async function download_action( e )
    {
    	let canvasUrl = $( '#faceAPIOverlay' ).get( 0 ).toDataURL( "image/jpeg", 1 );
    	const createEl = document.createElement('a');
    		  createEl.href = canvasUrl;
    		  createEl.download = "tecno_my_tone_color";
    		  createEl.click();
    		  createEl.remove();

    	$( '#faceAPIOverlay' ).get( 0 ).toBlob( ( blob ) => window.open( URL.createObjectURL( blob ), '_blank' ) );
    }

	async function show_submit_form( e )
    {
    	$( 'section[ id^="section-" ].active' ).removeClass( 'active' );
    	$( 'section#section-2' ).addClass( 'active' );
    	$( '#smooth-content' ).addClass( 'bg-white' );
    	gsap.fromTo( $( 'section#section-2' ), { webkitFilter: "brightness(10) invert(1)", filter: "brightness(10) invert(1)" }, { webkitFilter: "brightness(1)", filter: "brightness(1)", duration: 0.75, ease: "power2.out" } );
    }

	async function hide_submit_form( e )
    {
    	$( 'section[ id^="section-" ].active' ).removeClass( 'active' );
    	$( 'section#section-1' ).addClass( 'active' );
    	$( '#smooth-content' ).removeClass( 'bg-white' );
    	gsap.fromTo( $( 'section#section-2' ), { webkitFilter: "brightness(10) invert(1)", filter: "brightness(10) invert(1)" }, { webkitFilter: "brightness(1)", filter: "brightness(1)", duration: 0.75, ease: "power2.out" } );
    }

	async function submit_form_action( e )
    {
    	e.preventDefault();

    	let url = $( this ).attr( "action" );
    	let type = $( this ).attr( "method" );
    	// let formData = $( "#section-2 .form-data form" ).serialize();
    	let formData = new FormData( this );
    	let missingFields = [ { name: 'agreement', value: '' } ];
    	let num_errors = 0;

    	for( var [ key, value ] of formData.entries() )
    	{
    		if( [ '_token', 'gallery' ].indexOf( key ) >= 0 ) continue;

    		let objIndex = missingFields.findIndex( (temp) => temp[ 'name' ] === key );
    		if( objIndex >= 0 )
    		{
    			missingFields[ objIndex ].value = value;
    		}

    		switch( key )
    		{
    			case 'handle':
    				var $ele = $( '.form-data input[ name = "' + key + '" ]' ).parents( '.frm-input' );
    					$ele[ value == '' ? 'addClass': 'removeClass' ]( 'error' );
    					// $ele.find( '.error-msg' ).html( value == '' ? 'Value cannot be empty.': '' );
    					if( value == '' ) num_errors++;
    				break;
    			// case 'ethnicity':
    			// 	var $ele = $( '.form-data input[ name = "' + key + '" ]' ).parents( '.frm-input' );
    			// 		$ele[ value == '' ? 'addClass': 'removeClass' ]( 'error' );
    			// 		// $ele.find( '.error-msg' ).html( value == '' ? 'Value cannot be empty.': '' );
    			// 		if( value == '' ) num_errors++;
    			// 	break;
    			// case 'country':
    			// 	var $ele = $( '.form-data input[ name = "' + key + '" ]' ).parents( '.frm-select' );
    			// 		$ele[ value == '' ? 'addClass': 'removeClass' ]( 'error' );
    			// 		// $ele.find( '.error-msg' ).html( value == '' ? 'Value cannot be empty.': '' );
    			// 		if( value == '' ) num_errors++;
    			// 	break;
    			case 'feedback':
    				var $ele = $( '.form-data textarea[ name = "' + key + '" ]' ).parents( '.frm-input' );
    					$ele[ value == '' ? 'addClass': 'removeClass' ]( 'error' );
    					// $ele.find( '.error-msg' ).html( value == '' ? 'Value cannot be empty.': '' );
    					if( value == '' ) num_errors++;
    				break;
    		}
		}

		for (var i = 0; i < missingFields.length; i++)
		{
    		switch( missingFields[ i ].name )
    		{
    			case 'agreement':
    				var $ele = $( '.form-data input[ name = "' + missingFields[ i ].name + '" ]' ).parents( '.frm-checkbox' );
    					$ele[ missingFields[ i ].value == '' ? 'addClass': 'removeClass' ]( 'error' );
    					// $ele.find( '.error-msg' ).html( missingFields[ i ].value == '' ? 'Value cannot be empty.': '' );
    					if( missingFields[ i ].value == '' ) num_errors++;
    				break;
    		}
		}

		if( num_errors == 0 )
		{
			let $form = $( "#section-2 .form-data form" );
			$form.find( 'input[ name = "tone" ]' ).val( recordedResult.tone );
			$form.find( 'input[ name = "image" ]' ).val( $( '#faceAPIOverlay' ).get( 0 ).toDataURL( "image/jpeg", 1 ) );
			formData = $( "#section-2 .form-data form" ).serialize();

	    	$.ajax({
	    		url: url,
	    		type: type,
	    		data: formData,
	    		success: (response) => {
	    			if( response )
	    			{
				    	hide_submit_form();
				    	modalReturn_close();
				    	splide.refresh();
	    				modalSubmitted_open();
	    			}
	            },
	            error: function( response ) {
	    			console.log( 'error', response.status, response.responseJSON );
	                alert( 'ERROR (' + response.status + ')\n' + response.responseJSON.message );
	            }
	        });
		}
    }

    async function runCamera( direction = 'user' )
    {
		if( !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia )
		{
		    console.log("mediaDevices is not supported.");
		    return false;
		}

		// load face detection model
    	await img_func.changeFaceDetector( img_func.TINY_FACE_DETECTOR );
    	// await changeFaceDetector(SSD_MOBILENETV1); // WEIRD ERROR

    	// alert( $( '.video-container' ).width() + " : " + $( '.video-container' ).height() );
		// try to access users webcam and stream the images to the video element
    	const stream = await navigator.mediaDevices.getUserMedia(
    		{
    			audio: false,
    			video: {
    				// width: { ideal: 375 },
    				// height: { ideal: 500 },
			        // width: 375,
			        // height: 500,
    				facingMode: direction // 'user' or 'environment'
    			}
    		} );
    	const video = $( '#faceAPIVideo' ).get( 0 );
    	video.srcObject = stream;

    	$( '#faceAPIVideo' )[ direction == 'user' ? 'addClass': 'removeClass' ]( 'user' );
		cam_facing_prev = cam_facing;

    	return video;
    }

	async function start_webapp( e )
    {
    	$( '.canvas-info' ).addClass( 'loading' );
    	$( '.canvas-info .title' ).removeClass( 'show-instructions' );
    	$( '.carousel-actions' ).addClass( 'loading' ).removeClass( 'disabled' );
    	
		let camera = runCamera();

		if( camera )
		{
			$( '.carousel-actions button.btn-snap' ).on( 'click', snap_action );
			$( '.carousel-actions button.btn-cam-facing' ).on( 'click', cam_facing_action );
			$( '.carousel-actions button.btn-retake' ).on( 'click', retake_action );
			$( '.carousel-actions button.btn-next' ).on( 'click', next_action );
			$( '.carousel-actions button.btn-submit' ).on( 'click', show_submit_form );
			// $( '.carousel-actions button.btn-download' ).on( 'click', download_action );
			$( '#section-2 .form-data form' ).on( 'submit', submit_form_action );
		}
    }

	// window.addEventListener( 'resize', _.debounce(() =>
	// {
	// 	console.log( 'why' );
	// 	const video = $( "#faceAPIVideo" ).get( 0 );
	// 	const facingMode = video.srcObject.getVideoTracks()[ 0 ].getConstraints().facingMode;
	//  	runCamera( facingMode );
	// }, 100 ));

	window.trackVideoAction = trackVideoAction;

	$( document ).ready( function()
	{
		$( '#modalReturn .btn-confirm' ).on( 'click', return_action );
		$( 'button.btn-got-it' ).on( 'click', start_webapp );

		splide = new Splide( '.carousel-actions', {
			type: 'slide',
			speed: 300,
			arrows: false,
			pagination: false,
			autoplay: false,
			drag: false,
			direction: 'ttb',
      		height: $( '.splide__list' ).height(),
      		// height: '100%',
      		easing: "cubic-bezier(0,1,.5,1)",
			// autoHeight: true,
			autoWidth: true,
			waitForTransition: true,
			pauseOnHover: false,
			pauseOnFocus: false,
		} );

    	splide.on( 'mounted', function()
    	{
    		let $track = $( splide.root ).find( '.splide__track' );
    		let eles = $track.find( 'div[ class^="step" ], div[ class*=" step" ]' );
    		let tgt_h = $( eles.get( splide.index ) ).find(">:first-child").outerHeight();
    		splide.options = { height: tgt_h, };
    		splide.refresh();
    	} );
    	splide.on( 'move resize', function( newIndex, prevIndex, destIndex )
    	{
    		let is_active = $( '#section-1' ).hasClass( 'active' );
    		let current_slide = splide.index;

    		if( !is_active ) $( '#section-1' ).addClass( 'active' );
    		if( destIndex == undefined ) destIndex = splide.index;
    		let $track = $( splide.root ).find( '.splide__track' );
    		let eles = $track.find( 'div[ class^="step" ], div[ class*=" step" ]' );
    		let tgt_h = $( eles.get( destIndex ) ).find(">:first-child").outerHeight();
    		splide.options = { height: tgt_h, start: current_slide, };
    		splide.refresh();
    		if( !is_active ) $( '#section-1' ).removeClass( 'active' );

    		switch( destIndex )
    		{
    			case 0:
					$( '.video-container >.face-api-info' ).removeClass( 'opacity-0' );
					$( '.video-holder >.tone-results' ).removeClass( 'hide' );
    				break;
    			case 1:
					$( '.video-container >.face-api-info' ).removeClass( 'opacity-0' );
					$( '.video-holder >.tone-results' ).removeClass( 'hide' );
    				break;
    			case 2:
					$( '.video-container >.face-api-info' ).addClass( 'opacity-0' );
					$( '.video-holder >.tone-results' ).addClass( 'hide' );
    				break;
    			default: break;
    		}
    	} );
    	splide.mount();


    	const numberOfLines = (text) => (text.match(/\n/g) || []).length + 1;
    	const maxRows = (textarea) => parseInt(textarea.attr('rows'));

    	$('textarea[data-limit-rows=true]').on('keypress', function (event) {
    		const textarea = $(this);

    		if (event.which === 13 && numberOfLines(textarea.val()) === maxRows(textarea)) {
    			return false;
    		}
    	}).on('paste', function (event) {
    		const textarea = $(this),
    		pastedText = (event.originalEvent || event).clipboardData.getData('text/plain');   

    		if (!pastedText || numberOfLines(textarea.val() + pastedText) > maxRows(textarea)) {
    			return false
    		}    
    	});

    });

    Draggable.create( $( "#modalContentLicense .modal-container" ),
    {
		type: "y",
		bounds: $( "#modalContentLicense .modal-holder" ),
		inertia: true,
		autoScroll: 1,
	});

    Draggable.create( $( "#modalDataUse .modal-container" ),
    {
		type: "y",
		bounds: $( "#modalDataUse .modal-holder" ),
		inertia: true,
		autoScroll: 1,
	});

    let t;
    const wheelHandler = (e) => {
    	console.log( e, e.velocityY );
    	// t && t.kill();
    	// t = gsap.to(".box", {
    	// 	x: "random(100, 300)",
    	// 	y: "random(100, 300)",
    	// 	duration: 1,
    	// 	ease: "power1.inOut",
    	// 	onComplete: () => d[0].update()
    	// });
    };

    const myObserver = Observer.create({
    	target: $( "#modalContentLicense .modal-container" ),
    	type: "wheel",
    	onWheel: wheelHandler
    });

    
})( 'body[data-section="webapp"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- DASHBOARD ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	$( window ).on( "resize", _.debounce(() =>
	{
	}, 100 ));

	document.addEventListener( 'alpine:init', () => {
	    // Alpine.data( 'modelOpen', () => ({
	    //     open: false,
	    //     image( url ) { this.$refs.image.src = url; },
	    //     toggle() { this.open = !this.open; },
		// 	init() {
		// 		this.$watch('open', () => {
		// 			gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
		// 		})
		// 	}
	    // })),
	    // Alpine.data( 'menuOpen', () => ({
	    //     open: false,
	    //     toggle() { this.open = !this.open; }
	    // }));
	});

	// window.modelOpen_open = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = true; ref.image( e.getElementsByTagName( 'img' )[ 0 ].getAttribute('src') );  }
	// window.modelOpen_close = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = false; }

	Alpine.start();

	$( document ).ready( function()
	{
		console.log( 'haha' );
	});

    
})( 'body[data-section="dashboard"]' );





